import "./AdsArea.css"
import {useEffect} from "react";

function AdsArea({page,categoryIndex}) {
    useEffect(()=>{
         console.log(`create  big div ${page}`)
         window.googletag = window.googletag || {cmd: []};
         window.adSlot3 = window.adSlot3 || null;
         window.adSlot1 = window.adSlot1 || null;
         if(page === "detail"){    
             let topAd = document.getElementById("top-ads");
                topAd.innerHTML = `<div id='div-gpt-ad-1701162123068063-0' style='min-width: 300px; min-height: 250px;'>`;
                window.googletag.cmd.push(function() {
                    window.adSlot3 = window.googletag.defineSlot('/22847376752/delishchronicle.top-f/delishchronicle.top-300x250-03', [300, 250], 'div-gpt-ad-1701162123068063-0').addService(window.googletag.pubads());
                    window.googletag.pubads().collapseEmptyDivs();
                    window.googletag.enableServices();
                  });
                 window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1701162123068063-0'); });      
             /**if(window.adSlot3 == null){
                
             }
             else{
                window.googletag.cmd.push(function() {
                    window.googletag.pubads().refresh([window.adSlot3]);
                  });
             }**/
         }
         else {
             if(window.adSlot1 == null){
                let topAd = document.getElementById("top-ads");
                 topAd.innerHTML = `<div id='div-gpt-ad-1701162123628055-0' style='min-width: 300px; min-height: 250px;'>`;
                window.googletag.cmd.push(function() {
                    window.adSlot1 = window.googletag.defineSlot('/22847376752/delishchronicle.top-f/delishchronicle.top-300x250-01', [300, 250], 'div-gpt-ad-1701162123628055-0').addService(window.googletag.pubads());
                    window.googletag.pubads().collapseEmptyDivs();
                    window.googletag.enableServices();
                 });
                 window.googletag.cmd.push(function() { window.googletag.display('div-gpt-ad-1701162123628055-0'); });
             }
             else{
                 window.googletag.cmd.push(function() {
                    window.googletag.pubads().refresh([window.adSlot1]);
                  });
             }
             
         }
         /**let topAd = document.getElementById("top-ads");
         topAd.innerHTML = `<div id='taboola-mobile-below-article-thumbnails-${page}'></div>`;
          window._taboola = window._taboola || [];
          window._taboola.push({
            mode: 'thumb-below-mobile',
            container: `taboola-mobile-below-article-thumbnails-${page}`,
            placement: `Mobile Below Article thumbnails-${page}`,
            target_type: 'mix'
          });**/
    }, [categoryIndex])
     
    return (
        <div id={'top-ads'} className={'ads-area'}></div>
    )
}

export default AdsArea;